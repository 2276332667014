<template>
  <v-container>
    <disposal-process-list/>
  </v-container>
</template>

<script>
import DisposalProcessList from "@/components/app_setting/disposal_process/DisposalProcessList";

export default {
  name: "ExecutionTypePage",
  components: {
    DisposalProcessList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Disposal Process'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>